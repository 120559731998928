<template>
  <div style="margin-bottom: 50px">
    <div v-if="vrUrl" class="tab-group">
      <h4>VR</h4>
      <ul class="tab-list">
        <li class="item">
          <img @click="goVr()" class="cursor" :src="coverImage ? coverImage : houseImage[0].serverPath" alt="" />
          <img class="vr" @click="goVr()" src="@/assets/icon/VR.png" alt="" />
        </li>
      </ul>
    </div>
    <div v-if="videoUrl" class="tab-group">
      <h4>视频</h4>
      <ul class="tab-list">
        <li class="item">
          <img @click="goVideo()" class="cursor" :src="coverImage ? coverImage : houseImage[0].serverPath" alt="" />
          <img class="vr" @click="goVideo()" src="@/assets/icon/video.png" alt="" />
        </li>
      </ul>
    </div>
    <div class="tab-group" v-show="item.imgs.length" v-for="(item, index) in photoType" :key="index">
      <h4>{{ item.name }}</h4>
      <ul class="tab-list">
        <li class="item" v-for="(img, index) in item.imgs" :key="index" @click="showBigImg(img, item.imgs, item.name)">
          <img class="item" :src="img.serverPath" alt="" />
          <!-- <el-image
            class="item"
            :src="img.serverPath"
            :preview-src-list="item.imgsUrl"
          >
          </el-image> -->
        </li>
      </ul>
    </div>
    <BigImgCarousel v-if="imgs" :isShowBigImg="isShowBigImg" :houseImage="imgs" :imgType='imgType' :newImage="clickImg"
      @close="isShowBigImg = false" />
  </div>
</template>

<script>
import realHouseServer from "../../../services/realHouseServer";
import newEstateService from "../../../services/newEstateService";
import BigImgCarousel from "../../../components/BigImgCarousel.vue";
export default {
  components: {
    BigImgCarousel,
  },
  props: ["estateGuid", "vrUrl", "videoUrl", "coverImage"],
  data() {
    return {
      houseImage: [],

      photoType: [],

      imgs: [],
      clickImg: {},
      isShowBigImg: false,
      imgType: "",
    };
  },
  methods: {
    showBigImg(img, imgs, imgType) {
      this.clickImg = img;
      this.imgs = imgs;
      this.isShowBigImg = true;
      this.imgType = imgType;
      console.log("this.imgs>>", this.imgs);
    },
    async getEstateParams() {
      const { data } = await newEstateService.getEstateParams({});

      // this.$set(this.estateParams, "region", result);
      let imageType = data.imageType;
      imageType.splice(0, 1);
      imageType.forEach((type) => {
        type.imgs = [];
        type.imgsUrl = [];
        this.houseImage.forEach((img) => {
          if (type.value == img.catalog) {
            type.imgs.push(img);
            type.imgsUrl.push(img.serverPath);
          }
        });
      });

      this.photoType = imageType;
      console.log("this.photoType>>>", this.photoType);
    },
    async loadHouseImags() {
      const { data } = await realHouseServer.getHouseresource(this.estateGuid);
      this.houseImage = data;
      this.getEstateParams();
    },
    goVr() {
      window.open(this.vrUrl);
    },
    goVideo() {
      window.open(this.videoUrl);
    },
  },
  created() {
    this.loadHouseImags();
  },
};
</script>

<style lang="less" scoped>
.tab-group {
  margin-top: 46px;
}

h4 {
  font-size: 16px;
  padding-bottom: 8px;
  font-weight: 700;
}

.tab-list {
  margin-left: -20px;
  display: flex;
  flex-wrap: wrap;
}

.tab-list .item {
  width: 236px;
  height: 178px;
  margin-left: 20px;
  margin-top: 20px;
  line-height: 178px;
  text-align: center;
  position: relative;

  img {
    max-width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

.vr {
  width: 48px !important;
  height: 48px !important;
  position: absolute;
  left: 94px;
  top: 65px;
  cursor: pointer;
}
</style>